import { Gender } from '@core/types/Gender';
import createDoctorNameParagraph from 'app/features/reports/add/utils/createDoctorNameParagraph';
import createProfessionalInfoParagraphs from 'app/features/reports/add/utils/createProfessionalInfoParagraphs';

type UserProfessionalInformation = {
  fullName: string;
  information: string;
  gender: Gender;
};

export default function replaceProfessionalInfo(
  page: HTMLDivElement,
  userProfessionalInformation: Partial<UserProfessionalInformation>,
) {
  const { fullName, information, gender } = userProfessionalInformation;

  const profesionalInfoPlaceholders = page.querySelectorAll(
    '.ProfessionalInfoPlaceholder',
  ) as NodeListOf<HTMLElement>;

  profesionalInfoPlaceholders.forEach((elem: HTMLElement) => {
    if (fullName || information) {
      const tempDiv = document.createElement('div');
      if (fullName) {
        tempDiv.appendChild(createDoctorNameParagraph(fullName, gender));
      }
      if (information) {
        createProfessionalInfoParagraphs(information).forEach((paragraph) => {
          tempDiv.appendChild(paragraph);
        });
      }
      elem.replaceWith(tempDiv);
    } else {
      elem.remove();
    }
  });
}
