import React, { useEffect, useRef, useState, useCallback } from 'react';
import QRCode from 'qrcode';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import getAbsoluteHeight from 'app/features/reports/add/utils/getAbsoluteHeight';
import splitPage from 'app/features/reports/add/utils/splitPage';
import { useGetSignature } from 'app/features/users/edit/hooks/useSignature';
import replacePlaceholders from '../../../../utils/replacePlaceholders';
import HeightSandBox from './HeightSandbox';
import Page from './Page';
import PreviewProps from './PreviewProps';
import useSx from './sx';

export const pageConfig = {
  size: {
    width: 816,
    height: 1054,
  },
  position: {
    x: 50,
    y: 50,
  },
  padding: {
    top: 50,
    right: 50,
    bottom: 50,
    left: 50,
  },
  fontFamily: 'Arial, Helvetica, sans-serif',
  fontSize: 14,
  lineHeight: 1,
};

const MAX_ALLOWED_SIZE_FOR_CONTENT =
  pageConfig.size.height - pageConfig.padding.top - pageConfig.padding.bottom;

const getTotalHeight = (element: HTMLElement) => {
  let totalHeight = 0;
  for (let i = 0; i < element.childNodes.length; i++) {
    const child = element.childNodes[i] as HTMLElement;
    if (child.nodeType === Node.ELEMENT_NODE) {
      // Sumar la altura del hijo y su margen superior e inferior
      totalHeight += getAbsoluteHeight(child);
    }
  }

  return totalHeight;
};

export const handlePageSplitting = (element: HTMLElement, maxHeight: number) => {
  let splittedNodes = [];
  const pages = [];
  do {
    splittedNodes = splitPage(element, maxHeight, pageConfig);

    const newPageContent = splittedNodes.reduce(
      (htmlContent, node) => htmlContent + (node instanceof Element ? node.outerHTML : ''),
      '',
    );
    pages.push(element.innerHTML);
    element.innerHTML = newPageContent;
  } while (splittedNodes.length > 0);

  const formattedPages = pages.map((page) => {
    const temp = document.createElement('div');
    temp.innerHTML = page;
    return temp.innerHTML;
  });

  return formattedPages;
};

const Preview = ({ content, customBackground, isLoading }: PreviewProps) => {
  const preview = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const [pagesContent, setPagesContent] = useState<string[]>([]);
  const [qrSrc, setQrSrc] = useState('');

  const { data, isFetched, isFetching } = useGetSignature();
  const { fullName, userProfileInfo } = useProfile();

  const professionalInfo = data?.professionalInfo;
  const signature = data?.signature;

  // Función debounced que actualiza el contenido del preview
  const updatePreview = useCallback(
    async (newContent: string) => {
      //This is needed because by defect in the editor the lineheight is set 1 by the parent, so we need to set it to 1 in the children
      // in case that they don't have a lineheight set
      const setLineHeihtToChildren = (element: HTMLElement) => {
        element.childNodes.forEach((node) => {
          if (node instanceof HTMLElement && !node.style.lineHeight) node.style.lineHeight = '1';
        });
      };

      if (preview.current && isFetched) {
        const div = document.createElement('div');
        div.innerHTML = newContent;
        setLineHeihtToChildren(div);

        replacePlaceholders(div, {
          professionalFullName: fullName,
          professionalInfo: data?.professionalInfo,
          signatureBase64: data?.signature,
          shareStudyQrBase64: qrSrc,
          professionalGender: userProfileInfo?.gender,
        });

        preview.current.innerHTML = div.innerHTML;

        //Logica para checar overflowing

        // Calcular la altura manualmente basada en los hijos
        const totalHeight = getTotalHeight(preview.current);

        const pages = [];

        if (totalHeight > MAX_ALLOWED_SIZE_FOR_CONTENT) {
          const newPages = handlePageSplitting(preview.current, MAX_ALLOWED_SIZE_FOR_CONTENT);
          pages.push(...newPages);
        } else {
          pages.push(preview.current.innerHTML);
        }
        setPagesContent(pages);

        setLoading(false); // Se detiene el loading cuando la operación finaliza
      }
    },
    // False positive, no es necesario agregar las dependencias
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [professionalInfo, signature, fullName, isFetched, qrSrc, userProfileInfo?.gender],
  );

  useEffect(() => {
    setLoading(true); // Comienza el loading al cambiar el contenido
    updatePreview(content); // Llama la función debounced
  }, [content, updatePreview]);

  useEffect(() => {
    async function generateAnchorImage() {
      const qrCode = document.createElement('canvas');
      const qrCodeBase64 = await QRCode.toDataURL(
        qrCode,
        process.env.REACT_APP_ADB2C_REDIRECT_URI!,
      );
      // Here we keep the anchorImage on memory
      setQrSrc(qrCodeBase64);
    }
    generateAnchorImage();
  }, []);

  const sx = useSx();

  return (
    <Box className="preview-container" sx={sx.scroll}>
      <Box className="preview" sx={sx.root}>
        <If condition={loading || isLoading || isFetching}>
          <Box sx={sx.loadingContainer}>
            <CircularProgress sx={sx.loading} />
          </Box>
        </If>
        <HeightSandBox
          customBackground={customBackground}
          heightSandboxRef={preview}
          pageConfig={pageConfig}
        />
        {pagesContent.map((page, index) => (
          <Page
            key={index}
            pageConfig={pageConfig}
            customBackground={customBackground}
            content={page}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Preview;
