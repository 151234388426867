import { Gender } from '@core/types/Gender';

const createDoctorNameParagraph = (fullname: string, gender?: Gender) => {
  const prefix = gender === 'Female' ? 'Dra.' : 'Dr.';
  const professionalInfo = fullname ? `${prefix} ${fullname}` : '';
  const professionalNameParagraph = document.createElement('p');
  professionalNameParagraph.innerText = professionalInfo;
  professionalNameParagraph.style.fontSize = '1rem';
  professionalNameParagraph.style.margin = '0';

  return professionalNameParagraph;
};

export default createDoctorNameParagraph;
